import React from 'react';
import Lottie from 'lottie-react';
import classNames from 'classnames';

const LoadingAnimation = {
  v: '5.9.6',
  fr: 60,
  ip: 0,
  op: 106,
  w: 215,
  h: 215,
  nm: 'ohif-progress',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 1/ohif-shape-new Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.797] },
              o: { x: [0.274], y: [0.135] },
              t: 25,
              s: [0],
            },
            {
              i: { x: [0.828], y: [0.97] },
              o: { x: [0.446], y: [0.039] },
              t: 52,
              s: [90],
            },
            {
              i: { x: [0.83], y: [1.207] },
              o: { x: [0.167], y: [-0.246] },
              t: 65,
              s: [90],
            },
            { t: 85, s: [180] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.667, y: 0.667 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [130.5, 131.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.806 },
              o: { x: 0.167, y: 0.006 },
              t: 9,
              s: [130.5, 131.75, 0],
              to: [2.917, 2.875, 0],
              ti: [-2.917, -2.875, 0],
            },
            {
              i: { x: 0.872, y: 0.872 },
              o: { x: 0.167, y: 0.167 },
              t: 40,
              s: [148, 149, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.856, y: 0.811 },
              o: { x: 0.167, y: 0.17 },
              t: 52,
              s: [148, 149, 0],
              to: [-0.881, -0.866, 0],
              ti: [1.995, 1.961, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0 },
              t: 76,
              s: [108.4, 113.023, 0],
              to: [-1.196, -1.176, 0],
              ti: [-2.169, -1.843, 0],
            },
            {
              i: { x: 0.833, y: 0.832 },
              o: { x: 0.167, y: 0.167 },
              t: 85,
              s: [108.4, 113, 0],
              to: [1.272, 1.081, 0],
              ti: [-1.819, -1.575, 0],
            },
            {
              i: { x: 0.833, y: 0.941 },
              o: { x: 0.167, y: 0.008 },
              t: 95,
              s: [115.214, 118.607, 0],
              to: [7.038, 6.091, 0],
              ti: [1.198, 1.177, 0],
            },
            { t: 106, s: [130.5, 131.8, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [21, 21, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                  ],
                  v: [
                    [-13, -16],
                    [13, -16],
                    [16, -13],
                    [16, 13],
                    [13, 16],
                    [-13, 16],
                    [-16, 13],
                    [-16, -13],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [21, 21], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 106,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 1/ohif-shape-new Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.79] },
              o: { x: [0.278], y: [0.149] },
              t: 25,
              s: [0],
            },
            {
              i: { x: [0.828], y: [1.005] },
              o: { x: [0.446], y: [0.022] },
              t: 52,
              s: [90],
            },
            {
              i: { x: [0.83], y: [1.207] },
              o: { x: [0.167], y: [-0.246] },
              t: 65,
              s: [90],
            },
            { t: 85, s: [180] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [81, 132, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.808 },
              o: { x: 0.167, y: 0.011 },
              t: 9,
              s: [81, 132, 0],
              to: [-3, 3, 0],
              ti: [3, -3, 0],
            },
            {
              i: { x: 0.872, y: 0.872 },
              o: { x: 0.167, y: 0.167 },
              t: 40,
              s: [63, 150, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.856, y: 0.812 },
              o: { x: 0.167, y: 0.174 },
              t: 52,
              s: [63, 150, 0],
              to: [0.906, -0.906, 0],
              ti: [-2.052, 2.052, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.002 },
              t: 76,
              s: [108.685, 113.315, 0],
              to: [1.217, -1.217, 0],
              ti: [2.674, -1.792, 0],
            },
            {
              i: { x: 0.833, y: 0.834 },
              o: { x: 0.167, y: 0.167 },
              t: 85,
              s: [108.7, 113.3, 0],
              to: [-1.593, 1.067, 0],
              ti: [2.246, -1.581, 0],
            },
            {
              i: { x: 0.833, y: 0.942 },
              o: { x: 0.167, y: 0.014 },
              t: 95,
              s: [99.965, 118.742, 0],
              to: [-8.674, 6.104, 0],
              ti: [-1.236, 1.236, 0],
            },
            { t: 106, s: [81, 132, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [21, 21, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                  ],
                  v: [
                    [-13, -16],
                    [13, -16],
                    [16, -13],
                    [16, 13],
                    [13, 16],
                    [-13, 16],
                    [-16, 13],
                    [-16, -13],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [21, 21], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 106,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 1/ohif-shape-new Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.79] },
              o: { x: [0.278], y: [0.149] },
              t: 25,
              s: [0],
            },
            {
              i: { x: [0.828], y: [0.943] },
              o: { x: [0.446], y: [0.022] },
              t: 52,
              s: [90],
            },
            {
              i: { x: [0.83], y: [1.207] },
              o: { x: [0.167], y: [-0.246] },
              t: 65,
              s: [90],
            },
            { t: 85, s: [180] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [131, 84.75, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.808 },
              o: { x: 0.167, y: 0.014 },
              t: 9,
              s: [131, 84.75, 0],
              to: [3, -3.125, 0],
              ti: [-3, 3.125, 0],
            },
            {
              i: { x: 0.872, y: 0.872 },
              o: { x: 0.167, y: 0.167 },
              t: 40,
              s: [149, 66, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.856, y: 0.812 },
              o: { x: 0.167, y: 0.175 },
              t: 52,
              s: [149, 66, 0],
              to: [-0.906, 0.946, 0],
              ti: [2.052, -2.143, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.013 },
              t: 76,
              s: [109.315, 112.893, 0],
              to: [-1.222, 1.276, 0],
              ti: [-2.102, 2.732, 0],
            },
            {
              i: { x: 0.833, y: 0.835 },
              o: { x: 0.167, y: 0.167 },
              t: 85,
              s: [109.3, 112.9, 0],
              to: [1.242, -1.615, 0],
              ti: [-1.796, 2.277, 0],
            },
            {
              i: { x: 0.833, y: 0.943 },
              o: { x: 0.167, y: 0.016 },
              t: 95,
              s: [115.852, 104.071, 0],
              to: [6.96, -8.823, 0],
              ti: [1.236, -1.29, 0],
            },
            { t: 106, s: [131, 84.8, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [21, 21, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                  ],
                  v: [
                    [-13, -16],
                    [13, -16],
                    [16, -13],
                    [16, 13],
                    [13, 16],
                    [-13, 16],
                    [-16, 13],
                    [-16, -13],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [21, 21], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 106,
      st: 0,
      ct: 1,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Layer 1/ohif-shape-new Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.79] },
              o: { x: [0.278], y: [0.149] },
              t: 25,
              s: [0],
            },
            {
              i: { x: [0.823], y: [1] },
              o: { x: [0.446], y: [-0.058] },
              t: 52,
              s: [90],
            },
            {
              i: { x: [0.83], y: [1.207] },
              o: { x: [0.167], y: [-0.246] },
              t: 65,
              s: [90],
            },
            { t: 85, s: [180] },
          ],
          ix: 10,
        },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.167 },
              t: 0,
              s: [80.5, 85, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.833, y: 0.806 },
              o: { x: 0.167, y: 0.005 },
              t: 9,
              s: [80.5, 85, 0],
              to: [-2.75, -3, 0],
              ti: [2.75, 3, 0],
            },
            {
              i: { x: 0.872, y: 0.872 },
              o: { x: 0.167, y: 0.167 },
              t: 40,
              s: [64, 67, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            {
              i: { x: 0.856, y: 0.812 },
              o: { x: 0.167, y: 0.177 },
              t: 52,
              s: [64, 67, 0],
              to: [0.831, 0.906, 0],
              ti: [-1.881, -2.052, 0],
            },
            {
              i: { x: 0.833, y: 0.833 },
              o: { x: 0.167, y: 0.025 },
              t: 76,
              s: [109.295, 112.685, 0],
              to: [1.112, 1.213, 0],
              ti: [2.768, 2.657, 0],
            },
            {
              i: { x: 0.833, y: 0.837 },
              o: { x: 0.167, y: 0.167 },
              t: 85,
              s: [109.3, 112.7, 0],
              to: [-1.648, -1.581, 0],
              ti: [2.299, 2.234, 0],
            },
            {
              i: { x: 0.833, y: 0.943 },
              o: { x: 0.167, y: 0.019 },
              t: 95,
              s: [100.116, 104.028, 0],
              to: [-8.935, -8.681, 0],
              ti: [-1.136, -1.239, 0],
            },
            { t: 106, s: [80.5, 85, 0] },
          ],
          ix: 2,
          l: 2,
        },
        a: { a: 0, k: [21, 21, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.657, 0],
                    [0, 0],
                    [0, 1.657],
                    [0, 0],
                    [-1.657, 0],
                    [0, 0],
                    [0, -1.657],
                  ],
                  v: [
                    [-13, -16],
                    [13, -16],
                    [16, -13],
                    [16, 13],
                    [13, 16],
                    [-13, 16],
                    [-16, 13],
                    [-16, -13],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: { a: 0, k: [1, 1, 1, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 2, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [21, 21], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 106,
      st: 0,
      ct: 1,
      bm: 0,
    },
  ],
  markers: [],
};

const LoadingIndicator = ({ className }) => {
  return (
    <div
      className={classNames(
        'absolute z-50 top-0 left-0 flex flex-col items-center justify-center',
        className
      )}
    >
      <Lottie animationData={LoadingAnimation} />;
    </div>
  );
};

export default LoadingIndicator;
