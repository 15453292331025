import AIProbe from './AIProbeMapping';

const measurementServiceMappingsFactory = (
  MeasurementService,
  DisplaySetService,
  CornerstoneViewportService
) => {
  return {
    AIProbe: {
      toAnnotation: AIProbe.toAnnotation,
      toMeasurement: csToolsAnnotation =>
        AIProbe.toMeasurement(
          csToolsAnnotation,
          DisplaySetService,
          CornerstoneViewportService
        ),
      matchingCriteria: [
        {
          valueType: MeasurementService.VALUE_TYPES.POINT,
          points: 1,
        },
      ],
    },
  };
};

export default measurementServiceMappingsFactory;
