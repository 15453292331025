import { vec2 } from 'gl-matrix';

import {
  Types,
  metaData,
  triggerEvent,
  eventTarget,
  utilities,
  getEnabledElement,
  VolumeViewport,
} from '@cornerstonejs/core';
import {
  AnnotationTool,
  annotation as cstAnnotation,
  drawing as cstDrawing,
  utilities as cstUtils,
  cursors,
  Enums as cstEnums,
} from '@cornerstonejs/tools';

const { elementCursor } = cursors;
const { hideElementCursor, resetElementCursor } = elementCursor;

class AIProbeTool extends AnnotationTool {
  static toolName;

  touchDragCallback: any;
  mouseDragCallback: any;
  editData: {
    annotation: any;
    viewportIdsToRender: string[];
    newAnnotation?: boolean;
  } | null;
  eventDispatchDetail: {
    viewportId: string;
    renderingEngineId: string;
  };
  isDrawing: boolean;
  isHandleOutsideImage: boolean;

  constructor(
    toolProps = {},
    defaultToolProps = {
      supportedInteractionTypes: ['Mouse', 'Touch'],
      configuration: {
        shadow: true,
        preventHandleOutsideImage: false,
        showMessageCallback,
      },
    }
  ) {
    super(toolProps, defaultToolProps);
  }

  // Not necessary for this tool but needs to be defined since it's an abstract
  // method from the parent class.
  isPointNearTool(): boolean {
    return false;
  }

  toolSelectedCallback() { }

  /**
   * Based on the current position of the mouse and the current imageId to create
   * a Probe Annotation and stores it in the annotationManager
   *
   * @param evt -  EventTypes.NormalizedMouseEventType
   * @returns The annotation object.
   *
   */
  addNewAnnotation = evt => {
    const eventDetail = evt.detail;
    const { currentPoints, element } = eventDetail;
    const worldPos = currentPoints.world;

    const enabledElement = getEnabledElement(element);
    const {
      viewport,
      renderingEngine,
      viewportId,
      renderingEngineId,
    } = enabledElement;

    this.isDrawing = true;
    const camera = viewport.getCamera();
    const { viewPlaneNormal, viewUp } = camera;

    const referencedImageId = this.getReferencedImageId(
      viewport,
      worldPos,
      viewPlaneNormal,
      viewUp
    );

    const instance = metaData.get('instance', referencedImageId);
    const { PatientID } = instance;

    const annotation = {
      invalidated: true,
      highlighted: true,
      metadata: {
        toolName: this.getToolName(),
        viewPlaneNormal: <Types.Point3>[...viewPlaneNormal],
        viewUp: <Types.Point3>[...viewUp],
        FrameOfReferenceUID: viewport.getFrameOfReferenceUID(),
        referencedImageId,
      },
      data: {
        label: '',
        handles: { points: [<Types.Point3>[...worldPos]] },
        cachedStats: {},
      },
    };

    const annotationUID = cstAnnotation.state.addAnnotation(
      element,
      annotation
    );

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');
    myHeaders.append('Access-Control-Allow-Origin', '*');

    var raw = JSON.stringify({
      fid: 1,
      case: PatientID,
      model_name: 'Densenet_T2_ABK_auc_079_nozone',
      zone: '',
      lps: worldPos,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
    };

    fetch('https://mycustomaibackend.com/predict', requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        const { score } = res;

        // get the annotation by UID
        const annotation = cstAnnotation.state.getAnnotation(annotationUID);

        const imageId = annotation.metadata.referencedImageId;

        // update the cached stats for the key that includes the imageId as
        // part of the key
        Object.keys(annotation.data.cachedStats).forEach(key => {
          if (key.includes(imageId)) {
            annotation.data.cachedStats[key] = {
              ...annotation.data.cachedStats[key],
              prediction: Number(score) * 100,
            };
          }
        });

        // Dispatching annotation modified
        const eventType = cstEnums.Events.ANNOTATION_MODIFIED;

        const eventDetail = {
          annotation,
          viewportId,
          renderingEngineId,
        };

        triggerEvent(eventTarget, eventType, eventDetail);
      })
      .catch(error => console.debug('error', error));

    this.configuration.showMessageCallback(
      evt.detail,
      annotationUID,
      response => {
        console.log("Response=", response);
      },
      evt.detail
    );

    const viewportIdsToRender = cstUtils.viewportFilters.getViewportIdsWithToolToRender(
      element,
      this.getToolName()
    );

    this.editData = {
      annotation,
      newAnnotation: true,
      viewportIdsToRender,
    };
    this._activateModify(element);

    hideElementCursor(element);

    evt.preventDefault();

    cstUtils.triggerAnnotationRenderForViewportIds(
      renderingEngine,
      viewportIdsToRender
    );

    return annotation;
  };

  /**
   * It checks if the mouse click is near ProveTool, it overwrites the baseAnnotationTool
   * getHandleNearImagePoint method.
   *
   * @param element - The element that the tool is attached to.
   * @param annotation - The annotation object associated with the annotation
   * @param canvasCoords - The coordinates of the mouse click on canvas
   * @param proximity - The distance from the mouse cursor to the point
   * that is considered "near".
   * @returns The handle that is closest to the cursor, or null if the cursor
   * is not near any of the handles.
   */
  getHandleNearImagePoint(element, annotation, canvasCoords, proximity) {
    const enabledElement = getEnabledElement(element);
    const { viewport } = enabledElement;

    const { data } = annotation;
    const point = data.handles.points[0];
    const annotationCanvasCoordinate = viewport.worldToCanvas(point);

    const near =
      vec2.distance(canvasCoords, annotationCanvasCoordinate) < proximity;

    if (near === true) {
      return point;
    }
  }

  handleSelectedCallback(evt, annotation, handle, interactionType = 'mouse') {
    const eventDetail = evt.detail;
    const { element } = eventDetail;

    annotation.highlighted = true;

    const viewportIdsToRender = cstUtils.viewportFilters.getViewportIdsWithToolToRender(
      element,
      this.getToolName()
    );

    // Find viewports to render on drag.

    this.editData = {
      //handle, // This would be useful for other tools with more than one handle
      annotation,
      viewportIdsToRender,
    };
    this._activateModify(element);

    hideElementCursor(element);

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = enabledElement;

    cstUtils.triggerAnnotationRenderForViewportIds(
      renderingEngine,
      viewportIdsToRender
    );

    evt.preventDefault();
  }

  _mouseUpCallback = evt => {
    const eventDetail = evt.detail;
    const { element } = eventDetail;

    const { annotation, viewportIdsToRender, newAnnotation } = this.editData;

    annotation.highlighted = false;

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = enabledElement;

    const { viewportId } = enabledElement;
    this.eventDispatchDetail = {
      viewportId,
      renderingEngineId: renderingEngine.id,
    };

    this._deactivateModify(element);

    resetElementCursor(element);

    this.editData = null;
    this.isDrawing = false;

    if (
      this.isHandleOutsideImage &&
      this.configuration.preventHandleOutsideImage
    ) {
      cstAnnotation.state.removeAnnotation(annotation.annotationUID, element);
    }

    cstUtils.triggerAnnotationRenderForViewportIds(
      renderingEngine,
      viewportIdsToRender
    );

    if (newAnnotation) {
      const eventType = cstEnums.Events.ANNOTATION_COMPLETED;

      const eventDetail = {
        annotation,
      };

      triggerEvent(eventTarget, eventType, eventDetail);
    }
  };

  _mouseDragCallback = evt => {
    this.isDrawing = true;
    const eventDetail = evt.detail;
    const { currentPoints, element } = eventDetail;
    const worldPos = currentPoints.world;

    const { annotation, viewportIdsToRender } = this.editData;
    const { data } = annotation;

    data.handles.points[0] = [...worldPos];
    annotation.invalidated = true;

    const enabledElement = getEnabledElement(element);
    const { renderingEngine } = enabledElement;

    cstUtils.triggerAnnotationRenderForViewportIds(
      renderingEngine,
      viewportIdsToRender
    );
  };

  cancel = (element: HTMLDivElement) => {
    // If it is mid-draw or mid-modify
    if (this.isDrawing) {
      this.isDrawing = false;
      this._deactivateModify(element);
      resetElementCursor(element);

      const { annotation, viewportIdsToRender, newAnnotation } = this.editData;
      const { data } = annotation;

      annotation.highlighted = false;
      data.handles.activeHandleIndex = null;

      const enabledElement = getEnabledElement(element);
      const { renderingEngine } = enabledElement;

      cstUtils.triggerAnnotationRenderForViewportIds(
        renderingEngine,
        viewportIdsToRender
      );

      if (newAnnotation) {
        const eventType = cstEnums.Events.ANNOTATION_COMPLETED;

        const eventDetail = {
          annotation,
        };

        triggerEvent(eventTarget, eventType, eventDetail);
      }

      this.editData = null;
      return annotation.annotationUID;
    }
  };

  _activateModify = element => {
    element.addEventListener(cstEnums.Events.MOUSE_UP, this._mouseUpCallback);
    element.addEventListener(
      cstEnums.Events.MOUSE_DRAG,
      this._mouseDragCallback
    );
    element.addEventListener(
      cstEnums.Events.MOUSE_CLICK,
      this._mouseUpCallback
    );

    // element.addEventListener(cstEnums.Events.TOUCH_END, this._mouseUpCallback)
    // element.addEventListener(cstEnums.Events.TOUCH_DRAG, this._mouseDragCallback)
  };

  _deactivateModify = element => {
    element.removeEventListener(
      cstEnums.Events.MOUSE_UP,
      this._mouseUpCallback
    );
    element.removeEventListener(
      cstEnums.Events.MOUSE_DRAG,
      this._mouseDragCallback
    );
    element.removeEventListener(
      cstEnums.Events.MOUSE_CLICK,
      this._mouseUpCallback
    );

    // element.removeEventListener(cstEnums.Events.TOUCH_END, this._mouseUpCallback)
    // element.removeEventListener(cstEnums.Events.TOUCH_DRAG, this._mouseDragCallback)
  };

  /**
   * it is used to draw the probe annotation in each
   * request animation frame. It calculates the updated cached statistics if
   * data is invalidated and cache it.
   *
   * @param enabledElement - The Cornerstone's enabledElement.
   * @param svgDrawingHelper - The svgDrawingHelper providing the context for drawing.
   */
  renderAnnotation = (enabledElement, svgDrawingHelper): boolean => {
    let renderStatus = false;
    const { viewport } = enabledElement;
    const { element } = viewport;

    let annotations = cstAnnotation.state.getAnnotations(
      element,
      this.getToolName()
    );

    if (!annotations?.length) {
      return renderStatus;
    }

    annotations = this.filterInteractableAnnotationsForElement(
      element,
      annotations
    );

    if (!annotations?.length) {
      return renderStatus;
    }

    const targetId = this.getTargetId(viewport);
    const renderingEngine = viewport.getRenderingEngine();

    const styleSpecifier = {
      toolGroupId: this.toolGroupId,
      toolName: this.getToolName(),
      viewportId: enabledElement.viewport.id,
    };

    for (let i = 0; i < annotations.length; i++) {
      const annotation = annotations[i];
      const annotationUID = annotation.annotationUID;
      const data = annotation.data;
      const point = data.handles.points[0];
      const canvasCoordinates = viewport.worldToCanvas(point);

      styleSpecifier.annotationUID = annotationUID;

      const color = this.getStyle('color', styleSpecifier, annotation);

      if (!data.cachedStats[targetId]) {
        data.cachedStats[targetId] = {
          Modality: null,
          index: null,
          value: null,
        };

        this._calculateCachedStats(annotation, renderingEngine, enabledElement);
      } else if (annotation.invalidated) {
        this._calculateCachedStats(annotation, renderingEngine, enabledElement);

        // If the invalidated data is as a result of volumeViewport manipulation
        // of the tools, we need to invalidate the related stackViewports data if
        // they are not at the referencedImageId, so that
        // when scrolling to the related slice in which the tool were manipulated
        // we re-render the correct tool position. This is due to stackViewport
        // which doesn't have the full volume at each time, and we are only working
        // on one slice at a time.
        if (viewport instanceof VolumeViewport) {
          const { referencedImageId } = annotation.metadata;

          // invalidate all the relevant stackViewports if they are not
          // at the referencedImageId
          for (const targetId in data.cachedStats) {
            if (targetId.startsWith('imageId')) {
              const viewports = renderingEngine.getStackViewports();

              const invalidatedStack = viewports.find(vp => {
                // The stack viewport that contains the imageId but is not
                // showing it currently
                const referencedImageURI = utilities.imageIdToURI(
                  referencedImageId
                );
                const hasImageURI = vp.hasImageURI(referencedImageURI);
                const currentImageURI = utilities.imageIdToURI(
                  vp.getCurrentImageId()
                );
                return hasImageURI && currentImageURI !== referencedImageURI;
              });

              if (invalidatedStack) {
                delete data.cachedStats[targetId];
              }
            }
          }
        }
      }

      // If rendering engine has been destroyed while rendering
      if (!viewport.getRenderingEngine()) {
        console.warn('Rendering Engine has been destroyed');
        return renderStatus;
      }

      const handleGroupUID = '0';

      cstDrawing.drawHandles(
        svgDrawingHelper,
        annotationUID,
        handleGroupUID,
        [canvasCoordinates],
        { color }
      );

      renderStatus = true;

      const isPreScaled = false;

      const textLines = this._getTextLines(data, targetId, isPreScaled);
      if (textLines) {
        const textCanvasCoordinates = [
          canvasCoordinates[0] + 6,
          canvasCoordinates[1] - 6,
        ];

        const textUID = '0';
        cstDrawing.drawTextBox(
          svgDrawingHelper,
          annotationUID,
          textUID,
          textLines,
          [textCanvasCoordinates[0], textCanvasCoordinates[1]],
          this.getLinkedTextBoxStyle(styleSpecifier, annotation)
        );
      }
    }

    return renderStatus;
  };

  _getTextLines(
    data,
    targetId: string,
    isPreScaled: boolean
  ): string[] | undefined {
    const cachedVolumeStats = data.cachedStats[targetId];
    const { index, Modality, value, SUVBw, SUVLbm, SUVBsa } = cachedVolumeStats;

    if (value === undefined && SUVBw === undefined) {
      return;
    }

    const textLines = [];

    // Check if we have scaling for the other 2 SUV types for the PET.
    if (Modality === 'PT' && isPreScaled === true && SUVBw !== undefined) {
      textLines.push(`${SUVBw.toFixed(2)} SUV bw`);
      if (SUVLbm) {
        textLines.push(`${SUVLbm.toFixed(2)} SUV lbm`);
      }
      if (SUVBsa) {
        textLines.push(`${SUVBsa.toFixed(2)} SUV bsa`);
      }
    }
    return textLines;
  }

  _getValueForModality(value, imageVolume, modality) {
    const values = {};

    values['value'] = value;

    // Check if we have scaling for the other 2 SUV types for the PET.
    if (
      modality === 'PT' &&
      imageVolume.scaling.PET &&
      (imageVolume.scaling.PET.suvbwToSuvbsa ||
        imageVolume.scaling.PET.suvbwToSuvlbm)
    ) {
      const { suvbwToSuvlbm, suvbwToSuvbsa } = imageVolume.scaling.PET;

      values['SUVBw'] = value;

      if (suvbwToSuvlbm) {
        const SUVLbm = value * suvbwToSuvlbm;
        values['SUVLbm'] = SUVLbm;
      }

      if (suvbwToSuvbsa) {
        const SUVBsa = value * suvbwToSuvbsa;
        values['SUVBsa'] = SUVBsa;
      }
    }

    return values;
  }

  _calculateCachedStats(annotation, renderingEngine, enabledElement) {
    const data = annotation.data;
    const { viewportId, renderingEngineId } = enabledElement;

    const worldPos = data.handles.points[0];
    const { cachedStats } = data;

    const targetIds = Object.keys(cachedStats);

    for (let i = 0; i < targetIds.length; i++) {
      const targetId = targetIds[i];

      const image = this.getTargetIdImage(targetId, renderingEngine);

      // If image does not exists for the targetId, skip. This can be due
      // to various reasons such as if the target was a volumeViewport, and
      // the volumeViewport has been decached in the meantime.
      if (!image) {
        continue;
      }

      const { dimensions, scalarData, imageData, metadata } = image;

      const modality = metadata.Modality;
      const index = utilities.transformWorldToIndex(imageData, worldPos);

      index[0] = Math.round(index[0]);
      index[1] = Math.round(index[1]);
      index[2] = Math.round(index[2]);

      if (utilities.indexWithinDimensions(index, dimensions)) {
        this.isHandleOutsideImage = false;
        const yMultiple = dimensions[0];
        const zMultiple = dimensions[0] * dimensions[1];

        const value =
          scalarData[index[2] * zMultiple + index[1] * yMultiple + index[0]];

        // Index[2] for stackViewport is always 0, but for visualization
        // we reset it to be imageId index
        if (targetId.startsWith('imageId:')) {
          const imageId = targetId.split('imageId:')[1];
          const imageURI = utilities.imageIdToURI(imageId);
          const viewports = utilities.getViewportsWithImageURI(
            imageURI,
            renderingEngineId
          );

          const viewport = viewports[0];

          index[2] = viewport.getCurrentImageIdIndex();
        }

        const values = this._getValueForModality(value, image, modality);

        cachedStats[targetId] = {
          index,
          ...values,
          Modality: modality,
        };
      } else {
        this.isHandleOutsideImage = true;
        cachedStats[targetId] = {
          index,
          Modality: modality,
        };
      }

      annotation.invalidated = false;

      // Dispatching annotation modified
      const eventType = cstEnums.Events.ANNOTATION_MODIFIED;

      const eventDetail = {
        annotation,
        viewportId,
        renderingEngineId,
      };

      triggerEvent(eventTarget, eventType, eventDetail);
    }

    return cachedStats;
  }
}

function showMessageCallback(doneChangingTextCallback) {
  return doneChangingTextCallback(prompt('Enter your annotation:'));
}

AIProbeTool.toolName = 'AIProbe';
export default AIProbeTool;
