import { addTool } from '@cornerstonejs/tools';
import AIProbeTool from './tools/AIProbeTool';

import { addIcon } from '@ohif/ui';

import aiProbe from './ai-probe.svg';
import measurementServiceMappingsFactory from './measurementServiceMappingsFactory';

const CORNERSTONE_3D_TOOLS_SOURCE_NAME = 'Cornerstone3DTools';
const CORNERSTONE_3D_TOOLS_SOURCE_VERSION = '0.1';
/**
 *
/**
 * @param {object} configuration
 */
export default function init({ servicesManager, configuration = {} }) {
  const {
    MeasurementService,
    DisplaySetService,
    CornerstoneViewportService,
  } = servicesManager.services;

  addIcon('tool-ai-probe', aiProbe);
  addTool(AIProbeTool);

  const { AIProbe } = measurementServiceMappingsFactory(
    MeasurementService,
    DisplaySetService,
    CornerstoneViewportService
  );

  const csTools3DVer1MeasurementSource = MeasurementService.getSource(
    CORNERSTONE_3D_TOOLS_SOURCE_NAME,
    CORNERSTONE_3D_TOOLS_SOURCE_VERSION
  );

  MeasurementService.addMapping(
    csTools3DVer1MeasurementSource,
    'AIProbe',
    AIProbe.matchingCriteria,
    AIProbe.toAnnotation,
    AIProbe.toMeasurement
  );
}
