import React from 'react';
import {
  metaData,
  utilities,
  getEnabledElement as csGetEnabledElement,
  eventTarget,
  triggerEvent,
} from '@cornerstonejs/core';

import {
  annotation as cstAnnotations,
  utilities as cstUtils,
  Enums,
  Types,
} from '@cornerstonejs/tools';

import { Dialog } from '@ohif/ui';

const AI_RESULT_ID = 'ai-results';
const AI_TEST = 'ai-text';

const commandsModule = ({ servicesManager, extensionManager }) => {
  const {
    ViewportGridService,
    UINotificationService,
    DisplaySetService,
    HangingProtocolService,
    ToolGroupService,
    UIModalService,
    UIDialogService,
    CornerstoneViewportService,
  } = servicesManager.services;

  const utilityModule = extensionManager.getModuleEntry(
    '@ohif/extension-cornerstone.utilityModule.common'
  );

  const { getEnabledElement } = utilityModule.exports;
  const _getDefaultPosition = event => ({
    x: (event && event.currentPoints.client[0]) || 0,
    y: (event && event.currentPoints.client[1]) || 0,
  });

  function _getActiveViewportsEnabledElement() {
    const { activeViewportIndex } = ViewportGridService.getState();
    const { element } = getEnabledElement(activeViewportIndex) || {};
    const enabledElement = csGetEnabledElement(element);
    return enabledElement;
  }
  const actions = {
    runAIProbe: ({ }) => {
      const { viewport } = _getActiveViewportsEnabledElement();
      const { element } = viewport;
    },

    showMessageCallback: ({ callback, eventDetails, annotationUID }) => {
      eventTarget.addEventListener(Enums.Events.ANNOTATION_MODIFIED, evt => {
        const { annotation } = evt.detail;
        const { data, metadata, annotationUID: uid } = annotation;

        if (annotationUID !== uid) {
          return;
        }

        if (!data.cachedStats) {
          return;
        }

        const { cachedStats } = data;
        const { referencedImageId } = metadata;

        let prediction;
        Object.keys(cachedStats).forEach(key => {
          if (key.includes(referencedImageId)) {
            ({ prediction } = cachedStats[key]);
          }
        });

        if (!prediction) {
          return;
        }

        const divs = document.querySelectorAll(`div[id*=${AI_TEST}]`);

        if (!divs || divs.length === 0) {
          return;
        }

        const div = divs[divs.length - 1];

        if (!div) {
          return;
        }

        // replace the text with the new one with two decimal places
        div.innerHTML = `${prediction.toFixed(
          2
        )}\% chance of being significant cancer`;

        const eventDetails: Types.EventTypes.AnnotationModifiedEventDetail = {
          annotation,
          renderingEngineId: eventDetails.renderingEngineId,
          viewportId: eventDetails.viewportId,
        };

        triggerEvent(
          eventTarget,
          Enums.Events.ANNOTATION_COMPLETED,
          eventDetails
        );
      });

      UIDialogService.create({
        id: 'ai-results',
        isDraggable: false,
        showOverlay: true,
        defaultPosition: _getDefaultPosition(eventDetails),
        content: Dialog,
        contentProps: {
          title: 'AI Results',
          noCloseButton: true,
          value: { label: 'ai result' || '' },
          body: ({ value, setValue }) => {
            return (
              <div id={AI_TEST} className="p-4 bg-primary-dark text-white">
                Requesting AI prediction from server...
              </div>
            );
          },
          actions: [
            { id: 'cancel', text: 'Cancel', type: 'primary' },
            { id: 'save', text: 'Save', type: 'secondary' },
          ],
          onSubmit: ({ action, value }) => {
            switch (action.id) {
              case 'save': {
                break;
              }
              case 'cancel': {
                cstAnnotations.state.removeAnnotation(annotationUID);
                cstUtils.triggerAnnotationRender(eventDetails.element);
                break;
              }
            }
            UIDialogService.dismiss({ id: AI_RESULT_ID });
          },
        },
      });
    },
  };

  const definitions = {
    runAIProbe: {
      commandFn: actions.runAIProbe,
      storeContexts: [],
      options: {},
    },
    showMessageCallback: {
      commandFn: actions.showMessageCallback,
      storeContexts: [],
      options: {},
    },
  };

  return {
    actions,
    definitions,
  };
};

export default commandsModule;
