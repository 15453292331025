const AIProbe = {
  toAnnotation: measurement => { },

  /**
   * Maps cornerstone annotation event data to measurement service format.
   *
   * @param {Object} cornerstone Cornerstone event data
   * @return {Measurement} Measurement instance
   */
  toMeasurement: (
    csToolsEventDetail,
    DisplaySetService,
    CornerstoneViewportService
  ) => {
    const { annotation, viewportId } = csToolsEventDetail;
    const { metadata, data, annotationUID } = annotation;

    const measurement = {
      uid: annotationUID,
      // SOPInstanceUID,
      metadata,
      // referenceSeriesUID: SeriesInstanceUID,
      // referenceStudyUID: StudyInstanceUID,
      // frameNumber: mappedAnnotations[0]?.frameNumber || 1,
      toolName: metadata.toolName,
      // displaySetInstanceUID: displaySet.displaySetInstanceUID,
      label: data.label,
      data: data.cachedStats,
    };

    if (!metadata || !data) {
      console.warn('Length tool: Missing metadata or data');
      return null;
    }

    const { toolName, referencedImageId, FrameOfReferenceUID } = metadata;

    if (!referencedImageId) {
      console.warn('AI Probe tool: Missing referencedImageId');
      return null;
    }

    const cachedStatKeys = Object.keys(data.cachedStats).filter(targetId =>
      targetId.includes(referencedImageId)
    );

    if (!cachedStatKeys.length) {
      console.warn('AI Probe tool: Missing cachedStats');
      return measurement;
    }

    const { prediction } = data.cachedStats[cachedStatKeys[0]];

    if (!prediction) {
      return measurement;
    }

    const displayText = [`${prediction.toFixed(2)}% significant cancer`];
    measurement.displayText = displayText;
    return measurement;
  },
};

export default AIProbe;
